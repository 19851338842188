import React, {useState, useEffect, useContext} from 'react';
import {PieChart} from '@mui/x-charts';
import {Button, Card, CardContent, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';
import {DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import dayjs from "dayjs";
import ApiOrderManagement from '../services/api/ApiOrderManagement';
import ApiReport from '../services/api/ApiReport';
import {AuthContext} from '../App.js';

export default function AnnyReport() {
    const {
        isProgress,
        setIsLoading,
        setIsProgress,
    } = useContext(AuthContext);

    const [rows, setRows] = useState([]);

    const [search, setSearch] = useState({
        customerId: null,
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
        startDateLoad: null,
        endDateLoad: null
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterBuField, setFilterBuField] = useState([]);
    const [buChartData, setBuChartData] = useState([]);
    const [buChartDataDisable, setBuChartDataDisable] = useState([]);
    const [statusChartData, setStatusChartData] = useState([]);
    const [statusChartDataDisable, setStatusChartDataDisable] = useState([]);
    const [seriesDisable, setSeriesDisable] = useState([]);
    const [seriesStatusDisable, setSeriesStatusDisable] = useState([]);
    const handleBuChartLegendClick = (seriesName) => {
        let data = [...seriesDisable]

        if (data.includes(seriesName)) {
            data = data.filter(f => f !== seriesName)
        } else {
            data.push(seriesName)
        }

        let newData = []
        buChartData?.map(m => {
            const mapData = {
                label: m.label,
                value: data.includes(m.label) ? 0 : m.value
            }

            newData.push(mapData)
        })

        setSeriesDisable(data)
        setBuChartDataDisable([...newData]);
    };

    const handleStatusChartLegendClick = (seriesName) => {
        let data = [...seriesStatusDisable]

        if (data.includes(seriesName)) {
            data = data.filter(f => f !== seriesName)
        } else {
            data.push(seriesName)
        }

        let newData = []
        statusChartData?.map(m => {
            const mapData = {
                label: m.label,
                value: data.includes(m.label) ? 0 : m.value
            }

            newData.push(mapData)
        })

        setSeriesStatusDisable(data)
        setStatusChartDataDisable([...newData]);
    };

    // open page and get data
    useEffect(() => {
        getbuField();
        searchData();
    }, []);

    const getbuField = async () => {
        try {
            let resMasterBu = await ApiOrderManagement.masterBu();

            if (resMasterBu.data && resMasterBu.status === 200) {
                setIsLoading(true);
                let buArray = [];

                const buData = resMasterBu.data;
                buData.map((bu) => {
                    buArray.push(bu);
                });
                setFilterBuField(buArray);
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchData = async (requestData) => {
        try {
            let response = await ApiReport.getAnnyReportList(requestData);

            if (response.data && response.status === 200) {
                const data = response.data;

                if (data.results === null) {
                    setRows([]);
                    setBuChartData([]);
                    setStatusChartData([]);
                } else {
                    const rows = data.results.map((item, index) => ({...item, id: index}));
                    const buChartData = transformData(data.bu);
                    const statusChartData = transformData(data.status);

                    setRows(rows);
                    setBuChartData(buChartData);
                    setBuChartDataDisable(buChartData);
                    setStatusChartData(statusChartData);
                    setStatusChartDataDisable(statusChartData);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const transformData = (data) => {
        if (!data) {
            console.error('No data provided to transformData');
            return [];
        }
        const chartData = Object.keys(data).map(key => ({
            label: key,
            value: data[key]
        }));
        return chartData;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ padding: '16px' }}>
            <h2>Anny Report</h2>

            <Grid container spacing={3} columns={16} justifyContent="center">
                {/* First Pie Chart */}
                <Grid item xs={16} md={16} lg={16} xl={8}>
                    <Card sx={{height: '100%', padding: 2}}>
                        <CardContent style={{ position: 'relative' }}>
                            <Typography variant="h3" align="center" gutterBottom>
                                Business Unit Distribution
                            </Typography>
                            <div style={{height: '430px', width: '100%', marginTop: '60px'}}>
                                <PieChart
                                    margin={{left: 10, right: 400}}
                                    series={[
                                        {
                                            highlightScope: {fade: 'global', highlight: 'item'},
                                            faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                            data: buChartDataDisable,
                                        },
                                    ]}
                                    slotProps={{
                                        legend: {
                                            position: { vertical: 'top', horizontal: 'right' },
                                            labelStyle: {
                                                fontSize: 13,
                                                width: 260,
                                                textOverflow: "ellipsis"
                                            },
                                        },
                                    }}
                                />

                                <div style={{
                                    backgroundColor: 'white',
                                    right: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: 20,
                                    position: 'absolute',
                                    top: 90
                                }}>
                                    {buChartData.map((item, index) => (
                                        <div
                                            key={item.label}
                                            onClick={() => handleBuChartLegendClick(item.label)}
                                            style={{
                                                height: '30px',
                                                fontSize: '13px',
                                                margin: '0 10px',
                                                cursor: 'pointer',
                                                textOverflow: "ellipsis",
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: '275px',
                                                textDecoration: !seriesDisable.includes(item.label) ? 'none' : 'line-through',
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={16} md={16} lg={16} xl={8}>
                    <Card sx={{height: '100%', padding: 2}}>
                        <CardContent style={{ position: 'relative' }}>
                            <Typography variant="h3" align="center" gutterBottom>
                                Status_message
                            </Typography>
                            <div style={{height: '430px', width: '100%', marginTop: '60px'}}>
                                <PieChart
                                    margin={{left: 10, right: 400}}
                                    series={[
                                        {
                                            highlightScope: {fade: 'global', highlight: 'item'},
                                            faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                            data: statusChartDataDisable,
                                        },
                                    ]}
                                    // label text overflow i need show ...
                                    slotProps={{
                                        legend: {
                                            position: { vertical: 'top', horizontal: 'right' },
                                            labelStyle: {
                                                fontSize: 13,
                                                width: 260,
                                                textOverflow: "ellipsis"
                                            },
                                        },
                                    }}
                                />

                                <div style={{
                                    backgroundColor: 'white',
                                    right: 5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: 20,
                                    position: 'absolute',
                                    top: 90
                                }}>
                                    {statusChartData.map((item, index) => (
                                        <div
                                            key={item.label}
                                            onClick={() => handleStatusChartLegendClick(item.label)}
                                            style={{
                                                width: '275px',
                                                height: '30px',
                                                fontSize: '13px',
                                                margin: '0 10px',
                                                cursor: 'pointer',
                                                textOverflow: "ellipsis",
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textDecoration: !seriesStatusDisable.includes(item.label) ? 'none' : 'line-through',
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div style={{width: '100%'}}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        pt: 1, pl: {xs: 0, md: 1},
                        display: {xs: 'block', sm: 'block'},
                    }}
                >
                    <Grid container item sx={{mb: '5px', mt: '30px'}}>
                        <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                            <Typography variant="subtitle1">
                                วันที่อัพโหลด (From):
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        label={'DD/MM/YY'}
                                        value={search.startDate}
                                        slotProps={{
                                            textField: {size: 'small'},
                                            actionBar: {
                                                actions: ['clear'],
                                            },
                                        }}
                                        onChange={(newValue) => {
                                            setSearch(search => ({...search, startDate: newValue}));
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    '& legend': {display: 'none'},
                                                    '& fieldset': {top: 0},
                                                }}
                                            />
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                            <Typography variant="subtitle1">
                                วันที่อัพโหลด (To):
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        label={'DD/MM/YY'}
                                        value={search.endDate}
                                        slotProps={{
                                            textField: {size: 'small'},
                                            actionBar: {
                                                actions: ['clear'],
                                            },
                                        }}
                                        value={dayjs(search.endDate)}
                                        onChange={(newValue) => {
                                            setSearch(search => ({...search, endDate: newValue}));
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    '& legend': {display: 'none'},
                                                    '& fieldset': {top: 0},
                                                }}
                                            />
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                            <Typography variant="subtitle1">
                                วันเวลารถจะเข้ารับเอกสาร/Start Load (From):
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        label={'DD/MM/YY'}
                                        value={search.startDateLoad}
                                        slotProps={{
                                            textField: {size: 'small'},
                                            actionBar: {
                                                actions: ['clear'],
                                            },
                                        }}
                                        value={dayjs(search.startDateLoad)}
                                        onChange={(newValue) => {
                                            setSearch(search => ({...search, startDateLoad: newValue}));
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    '& legend': {display: 'none'},
                                                    '& fieldset': {top: 0},
                                                }}
                                            />
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                            <Typography variant="subtitle1">
                                วันเวลารถจะเข้ารับเอกสาร/Start Load (To):
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        label={'DD/MM/YY'}
                                        value={search.endDateLoad}
                                        slotProps={{
                                            textField: {size: 'small'},
                                            actionBar: {
                                                actions: ['clear'],
                                            },
                                        }}
                                        value={dayjs(search.endDateLoad)}
                                        onChange={(newValue) => {
                                            setSearch(search => ({...search, endDateLoad: newValue}));
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                size="small"
                                                fullWidth
                                                sx={{
                                                    '& legend': {display: 'none'},
                                                    '& fieldset': {top: 0},
                                                }}
                                            />
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                            <Typography variant="subtitle1">
                                ชื่อลูกค้า:
                            </Typography>
                            <Select
                                size="small"
                                sx={{mt: '5px'}}
                                fullWidth
                                value={search.customerId}
                                onChange={(e) => {
                                    setSearch(search => ({...search, customerId: e.target.value}));
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px'
                                        }
                                    }
                                }}
                            >
                                {filterBuField.map((bu) => (
                                    <MenuItem key={bu.id} value={bu.id}>
                                        {bu.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} item sx={{pt: 1}} container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="end"
                        >
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                    searchData()
                                }}
                                sx={{
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    width: '100px',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <br/>
            <div style={{height: 500, width: '100%'}}>
                <DataGrid checkboxSelection disableRowSelectionOnClick
                          columns={[
                              {field: 'bu', headerName: 'ชื่อลูกค้า', width: 150},
                              {field: 'order_status', headerName: 'สถานะ', width: 150},
                              {field: 'tms_status_message', headerName: 'หมายเหตุ', width: 400},
                              {field: 'site_name', headerName: 'Site Name', width: 300},
                              {field: 'created_date', headerName: 'Created Date', width: 200},
                              {field: 'presetline', headerName: 'Presetline', width: 150},
                              {field: 'bu_tracking_no', headerName: 'Tracking No', width: 200},
                              {field: 'origin_site', headerName: 'Origin Site', width: 150},
                              {field: 'destination_site', headerName: 'Destination Site', width: 150},
                              {field: 'origin_plan_pickup_date', headerName: 'Pickup Date', width: 200},
                          ]}
                          rows={rows}
                          getRowId={(row) => row.id}
                          slots={{
                              toolbar: CustomToolbar,
                          }}
                />
            </div>
        </div>
    );

    function searchData() {
        let requestData = {
            customerId: search.customerId,
            startDate: search.startDate?.format('YYYY-MM-DD'),
            endDate: search.endDate?.format('YYYY-MM-DD'),
            startDateLoad: search.startDateLoad?.format('YYYY-MM-DD'),
            endDateLoad: search.endDateLoad?.format('YYYY-MM-DD')
        }
        setIsLoading(true);
        setIsProgress(true);
        fetchData(requestData).then(res => {
            console.log("success", res);
            setIsLoading(false);
            setIsProgress(false);
        }).catch((e) => {
            console.log("error", e);
            setIsLoading(false);
            setIsProgress(false);
        });

        setIsProgress(false);
    }
}