import axios from "axios";
import {API} from "../../config/api";

export default class ApiReport {

    static async getReportLists() {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/power-bi/getreportlists',
                undefined,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async getReport(id, reportId, groupId) {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/power-bi/getreportlist/' + id + "/" + reportId + "/" + groupId,
                undefined,
                {headers: {
                        'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
                        'Accept': 'application/json', 'Content-Type': 'application/json'
                    }}
            );
        } catch (e) {
            return e;
        }
    }

    static async search(data)
    {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/power-bi/search',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getAnnyReportList(data)
    {
        try {
            return await axios.post(
                new API().getCusAPI()+'api/anny/report',
                // 'https://localhost:8000/api/anny/report',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
       } catch (e) {
            return e;
        }
    }
}